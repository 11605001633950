table {
  border: none;
  width: 100%;
  border-spacing: 0;
}

th,
td {
  padding: 1rem 1rem 1rem 0;
  text-align: left;
}

tr:nth-child(even) {
  background-color: rgb(204, 204, 204, 0.2);
}

td {
  font-size: 0.9rem;
}

.backButton:hover {
  background: #8b4000 !important;
}

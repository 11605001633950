* {
  box-sizing: border-box;
}

form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -75%);
}

p {
  font-size: 1.5rem;
}

input {
  width: 100%;
  margin-bottom: 20px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

input:invalid {
  border: 1px solid red;
}

button {
  background: rgb(167, 1, 1);
  color: white;
  padding: 0.5rem 2rem;
  border-radius: 25px;
  outline: none;
  border: none;
  cursor: pointer;
}

button:hover {
  background: darkred;
}

.loader {
  display: block;
  margin: 50px auto;
  stroke: rgba(0, 0, 0, 0.5);
}
